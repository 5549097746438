<template>
  <div class="home">
    <v-container class="my-6">
      <v-row>
        <v-col>
          <span class="text-h4" v-if="$store.getters.user">
            Hola, {{ $store.getters.user.name }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'Home'
}
</script>
